.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bannerBox {
  width: 100%;
}

.container-fluid {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

#wx {
  background: url('./assets/caizifengxing.png');
  width: 532px;
  height: 623px;
  left: 50%;
  z-index: 1000;
  position: relative;
  background-repeat: no-repeat;
  background-size: 532px 548px;
  top: 50%;
  margin-left: -266px;
  margin-top: -312px;
}

#wx div {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -25px;
}

#wx .close {
  width: 2.6vw;
  height: 2.6vw;
  cursor: pointer;
}

.App-header {
  background-color: #fff;
  padding: 1.56vw 18.5%;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 0.83vw;
}

.head-title {
  line-height: 37px;
  margin-right: 3.125vw;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: inherit;
  line-height: inherit;
}

.head-title:visited,
.head-title:hover {
  text-decoration: none;


}

.head-title:hover {
  color: #28DB8B;
  /* 继承父元素的字体颜色 */
}

.title-box {
  display: flex;
}

.first-head-title {
  margin-left: 1.71vw;
}

.head-title-active {
  color: #28DB8B;
  border-bottom: 3px solid #000000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.foot-img {
  width: 100%;
}

.primary-btn {
  font-size: 0.9vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 25px;
  width: 15vw;
  height: 49px;
  background: #000000;
  border-radius: 25px;
}

.row-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60vw;
  margin: 0 auto 60px auto;
}

.row-box div {
  width: 14vw;
  transition: transform 0.3s ease-in-out;
}

.row-box div img {
  width: 100%;
}

.row-box div:hover {
  transform: translateY(-20px);
}

.bgF6F6F6 {
  background-color: #f6f6f6;
}

.single-box {
  width: 64vw;
  margin: 0 auto 0 auto;
  padding-bottom: 4.58vw;
}

.single-box .title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 1.46vw;
  font-weight: 600;
  color: #000000;
  line-height: 1.77vw;
}

.cases {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 22px;
  justify-content: space-between;
}

.job-caces div {
  width: 24%;
}

.job-caces div img {
  width: 100%;
}

.img-box {
  margin: 0 auto;
  width: 62.5vw;
}

.carousel-row div {
  width: 19%;
}

.carousel-row div img {
  width: 100%;
}

.img-box img {
  width: 100%;
}

.company-pic-box div {
  width: 32%;
}

.company-pic-box div img {
  width: 100%;
}

.weight300 {
  font-weight: 300;
}

.mt90 {
  margin-top: 4.7vw;
}

.mt65 {
  margin-top: 3.4vw;
}

.carousel {
  width: 100%;
}

.carousel-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: url('./assets/student_work_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 55px 10%;
}

.about-content {
  width: 33.85vw;
}

.about-content div {
  font-size: 1.35vw;
  font-weight: 600;
  color: #333333;
  line-height: 1.92vw;
  margin-bottom: 12px;
}

.nowrap {
  flex-wrap: nowrap;
}

.text-left {
  text-align: left;
}

.about-content span {
  font-size: 0.93vw;
  font-weight: 600;
  color: #333333;
  line-height: 1.66vw;
}

.year-box>div {
  width: 20%;
  padding-right: 4vw;
}

.year-box>div img {
  width: 100%;
}

.year {
  font-size: 1.56vw;
  font-weight: 600;
  color: #333333;
  line-height: 1.87vw;
  letter-spacing: 1px;
  margin-top: 1.87vw;
  margin-bottom: 3px;
}

.year-content {
  font-size: 0.72vw;
  font-weight: 300;
  color: #333333;
  line-height: 1.66vw;
}

.cooperatives-box {
  row-gap: 3.125vw;
}

.cooperatives-box div {
  width: 19%;
}


.cooperatives-box div img {
  width: 100%;
}

.why-choose-box div img {
  width: 100%;
}

.why-choose-box> :nth-child(1) {
  width: 57.5%;
}

.why-choose-box> :nth-child(2) {
  width: 40%;
}

.why-choose-box> :nth-last-child(-n+3) {
  /* 样式规则 */
  width: 32%;
}

.bannerBox .carousel-control-prev {
  left: 10% !important;
}

.carousel-control-prev-icon {
  background-image: url('./assets/btn_left.png') !important;
}

.bannerBox .carousel-control-next {
  right: 10% !important;
}

.carousel-control-next-icon {
  background-image: url('./assets/btn_right.png') !important;
}

#about> :first-child {
  width: 19.1vw;
}

#about> :first-child img {
  width: 100%;
}

.pc {
  display: block;
}

.pc-flex {
  display: flex;
}

.mobile {
  display: none;

}

@media (max-width: 600px) {
  .mobile-logo {
    width: 100%;
  }

  .title-box {
    display: none;
  }

  .primary-btn {
    width: 34vw;
    height: 36px;
  }

  .pc {
    display: none;
  }

  .pc-flex {
    display: none;
  }

  .mobile {
    display: block;
  }

  .year {
    line-height: 3.87vw;
  }

  .year-content {
    line-height: 3.66vw;
  }


  #wx {
    width: 266px;
    height: 312px;
    left: 50%;
    z-index: 1000;
    position: relative;
    background-repeat: no-repeat;
    background-size: 266px 274px;
    top: 50%;
    margin-left: -133px;
    margin-top: -156px;
  }

  #wx div {
    margin-left: -12.5px;
  }

  #wx .close {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .title_box span {
    font-size: 18px;
  }

  .single-box {
    width: 90vw;

  }

  .row-box,
  .img-box {
    width: 90vw;
  }

  .row-box div {
    width: 20vw;
  }
}