.title_box {
  padding-top: 4.68vw;
  padding-bottom: 3.12vw;
  user-select: none;
}

.title_box span {
  font-size: 2.6vw;
  font-weight: 600;
  line-height: 3.6vw;
}

.black {
  color: #333333;
}

.green {
  color: #28DB8B;
}

.title_box .subtitle {
  font-size: 0.83vw;
  font-weight: 500;
  color: #C7C7CC;
  line-height: 22px;
}

@media (max-width: 600px) {
  .title_box>:last-child {
    width: 15vw;
    margin: auto;
  }

  .title_box>:last-child img {
    width: 100%;
  }
}